<template>
  <main class="view">
    <div class="col col-12 tabs-wrapper">
      <ul :class="['tab-controller', {hidden: !showTabs}]" style="--submenu-bg: #1B577A;">
        <li :class="['tab-controller__item', {active: listsView == 0, '--parent': Check(AdminRoles)}]">
          <router-link class="item-link" :to="{ name: 'users'}">{{ $t('Users') }}</router-link>
          <ul class="item-submenu">
            <!--
            <li class="item-submenu__item">
              <router-link class="submenu-link" :to="{ name: 'lists-overview'}"> Overview </router-link>
            </li>
            <li class="item-submenu__item">
              <router-link class="submenu-link" :to="{ name: 'lists-approve'}"> Approve </router-link>
            </li>
            -->
          </ul>
        </li>
        <li v-if="Check(AdminRoles)" :class="['tab-controller__item', {active: listsView == 1, '--parent': Check(AdminRoles)}]">
          <router-link class="item-link" :to="{ name: 'groups'}">{{ $t('Groups') }}</router-link>
          <ul class="item-submenu"></ul>
        </li>
      </ul>
      <router-view></router-view>
    </div>
  </main>
</template>

<script>
  import { Permissions } from '@/helpers/Permissions';
  import { Config } from '@/helpers/Config';
  import { BPA } from '@/helpers/BPA';

  export default {
    name: 'Users',
    mixins: [Permissions, Config, BPA],
    data() {
      return {
        dev: Config.dev,
        showTabs: false,
        listsView: 0,
        RouteMapper: {
          users: 0,
          groups: 1
        },
        AdminRoles: [/*0, 9*/ 'admin', 'users_admin'],
        route: null
      }
    },
    created() {
      this.IsInRouteMapper();
    },
    mounted() {
      this.showTabs = this.tabs.length > 1;
    },
    watch: {
      $route() {
        this.IsInRouteMapper();
      }
    },
    computed: {
      tabs() {
        return document.querySelectorAll('.tab-controller__item');
      }
    },
    methods: {
      IsInRouteMapper() {
        this.route = this.$route.name;
        let name = this.route.split('-')[0];
        if (this.RouteMapper[name] !== null) {
          this.listsView = this.RouteMapper[name];
        }
      },
      Check(required) {
        return BPA.permissions(required).length;
      },
      IsSuperUser() {
        return this.Check([/*0*/ 'admin']);
      },
      IsMainCompany() {
        return BPA.util.IsMainCompany();
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
